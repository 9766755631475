import styled from "@emotion/styled";
import { height, space, width } from "styled-system";

import { colors } from "@styles/colors";
import { TButton } from "@components/Button/types";

const StyledButton = styled.button<TButton>`
  display: flex;
  align-items: center;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  width: auto;
  border-radius: 28px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  text-transform: uppercase;
  vertical-align: middle;
  padding: 0 32px;
  transition: all 0.2s linear;
  position: relative;

  border-color: ${({ color, textButton }) => (textButton ? "transparent" : colors[color])};
  color: ${({ color, outline, textButton }) => (outline || textButton ? colors[color] : "#ffffff")};
  background-color: ${({ color, outline, textButton }) =>
    outline || textButton ? "transparent" : colors[color]};

  &:after {
    opacity: 0;
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    border-radius: 36px;
    border-width: 2px;
    border-style: solid;
    transition: opacity 0.2s linear;

    border-color: ${({ color }) => colors[color]};
  }

  &:hover {
    color: ${({ color, textButton }) => (textButton ? colors[`${color}Hover`] : "#ffffff")};
    border-color: ${({ color, textButton }) => !textButton && colors[`${color}Hover`]};
    background-color: ${({ color, textButton }) => !textButton && colors[`${color}Hover`]};
    transition: all 0.2s linear;
  }

  // Separator

  &:focus {
    &:after {
      opacity: 1;
      transition: opacity 0.2s linear;
    }
  }

  //&.full-width {
  //  width: 100%;
  //}
  //
  //&.green {
  //  color: #ffffff;
  //  border-color: var(--green-light);
  //  background-color: var(--green-light);
  //
  //  &:after {
  //    border-color: var(--green-light);
  //    box-shadow: 0 0 0 6px rgba(196, 215, 135, 0.1);
  //  }
  //
  //  &:hover {
  //    border-color: #b5c67e;
  //    background-color: #b5c67e;
  //    transition: all 0.2s linear;
  //  }
  //}
  //
  //&.red {
  //  color: #ffffff;
  //  border-color: var(--red-light);
  //  background-color: var(--red-light);
  //
  //  &:after {
  //    border-color: var(--red-light);
  //    box-shadow: 0 0 0 6px rgba(215, 135, 135, 0.1);
  //  }
  //
  //  &:hover {
  //    border-color: var(--red);
  //    background-color: var(--red);
  //    transition: all 0.2s linear;
  //  }
  //}
  //
  //&.white {
  //  color: var(--green-light);
  //  border-color: #ffffff;
  //  background-color: #ffffff;
  //
  //  &:after {
  //    border-color: rgb(255, 255, 255);
  //    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.1);
  //  }
  //}
  //
  //&.outline {
  //  background: none !important;
  //  position: relative;
  //
  //  &.primary {
  //    color: var(--main);
  //    border-color: var(--main);
  //    transition: all 0.2s linear;
  //
  //    &:after {
  //      border-color: var(--main);
  //      box-shadow: 0 0 0 6px rgba(38, 50, 56, 0.1);
  //    }
  //
  //    &:hover,
  //    &:focus {
  //      box-shadow: inset 0 0 0 2px var(--main);
  //    }
  //  }
  //
  //  &.green {
  //    color: var(--green-light);
  //    border-color: var(--green-light);
  //    transition: all 0.2s linear;
  //
  //    &:after {
  //      border-color: var(--main);
  //      box-shadow: 0 0 0 6px rgba(38, 50, 56, 0.1);
  //    }
  //
  //    &:hover,
  //    &:focus {
  //      box-shadow: inset 0 0 0 2px var(--green-light);
  //    }
  //  }
  //
  //  &.red {
  //    color: var(--red-light);
  //    border-color: var(--red-light);
  //    transition: all 0.2s linear;
  //
  //    &:after {
  //      border-color: var(--red-light);
  //      box-shadow: 0 0 0 6px rgba(215, 135, 135, 0.1);
  //    }
  //
  //    &:hover,
  //    &:focus {
  //      box-shadow: inset 0 0 0 2px var(--red-light);
  //    }
  //  }
  //}
  //
  //&.disabled:not(.outline) {
  //  border-color: #afafb1;
  //  background-color: #afafb1;
  //}
  //
  //&.disabled.outline {
  //  border-color: #afafb1;
  //  color: #afafb1;
  //  cursor: unset;
  //  &:hover {
  //    box-shadow: none;
  //  }
  //}

  ${space}
  ${width}
  ${height}
`;

export { StyledButton };
