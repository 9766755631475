import http from "../../../api/http";
import { TObjectsState } from "./types";
import { TAction } from "../../../redux/types";
import { Dispatch } from "redux";
import { LOADING, PAGINATION_SIZE } from "../../../constants";

import {
  buildFilterQueryString,
  // buildOrderQueryString,
  deserialize,
  serialize,
} from "../../../api/jsonApiParser";
import { TResponse } from "../../../api/types";

export const FETCHED_OBJECTS = "FETCHED_OBJECTS";
export const SET_FILTERING_CRITERIA_OBJECTS = "SET_FILTERING_CRITERIA_OBJECTS";
export const SET_ORDER_CRITERIA_OBJECTS = "SET_ORDER_CRITERIA_OBJECTS";
export const SET_OBJECTS_PAGE_NO = "SET_OBJECTS_PAGE_NO";
export const INCREMENT_OBJECTS_PAGE_NO = "INCREMENT_OBJECTS_PAGE_NO";

const initialState = {
  objects: [],
  objectsPageNo: 1,
  moreObjects: true,
  filter: undefined,
  order: [],
};

const ObjectsReducer = (state: TObjectsState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_OBJECTS: {
      let objects = [];
      const moreObjects = !(action.payload.length < PAGINATION_SIZE);

      if (state.objectsPageNo === 1 || action.page === 1) {
        objects = [...action.payload];
      } else {
        objects = [...state.objects, ...action.payload];
      }

      return { ...state, objects: objects, moreObjects: moreObjects };
    }

    case SET_OBJECTS_PAGE_NO: {
      const moreObjects = !(state.objects.length < (state.objectsPageNo + 1) * PAGINATION_SIZE);
      return { ...state, objectsPageNo: action.payload, moreObjects: moreObjects };
    }

    case SET_FILTERING_CRITERIA_OBJECTS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_OBJECTS_PAGE_NO: {
      return {
        ...state,
        objectsPageNo: state.objectsPageNo + 1,
      };
    }
  }
  return state;
};

export const setObjectsPageNo = (payload: number): TAction => ({
  type: SET_OBJECTS_PAGE_NO,
  payload,
});

export const setObjectsOrderCriteria = (payload: any): TAction => ({
  type: SET_ORDER_CRITERIA_OBJECTS,
  payload,
});

export const setObjectsFilterCriteria = (payload: any): TAction => ({
  type: SET_FILTERING_CRITERIA_OBJECTS,
  payload,
});

export const incrementObjectsPageNo = () => ({
  type: INCREMENT_OBJECTS_PAGE_NO,
});

export const fetchObjectsList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    const query = `Object/ObjectPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse) => {
        dispatch({
          type: FETCHED_OBJECTS,
          payload: (response.data as Record<string, unknown>[]).map(objects =>
            deserialize(objects)
          ),
          page: page,
        });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const createObject = (payload): Promise<TResponse> => {
  return http.post("Object/", serialize(payload, "Object"));
};

export const changeObject = (id: string, payload): Promise<TResponse> => {
  return http.put("Object/" + id, serialize(payload, "Object"));
};

export const deleteObject = (id: string): Promise<TResponse> => {
  return http.delete("Object/" + id);
};

export const getObjectById = (id: string): Promise<TResponse> => {
  return http.get("Object/" + id);
};

export { ObjectsReducer };
