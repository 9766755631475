import * as constants from "../../../constants";
import { ACTION } from "../../../shared/models/action";

import { buildFilterQueryString, deserialize, serialize } from "@api/jsonApiParser";
import { TDispatch } from "../../../redux/types";

import http from "@api/http";
import { TResponse } from "@api/types";

export const FETCHED_VERSIONS = "FETCHED_VERSIONS";
export const SET_FILTERING_CRITERIA_VERSIONS = "SET_FILTERING_CRITERIA_VERSIONS";
export const SET_VERSIONS_PAGE_NO = "SET_VERSIONS_PAGE_NO";
export const INCREMENT_VERSIONS_PAGE_NO = "INCREMENT_VERSIONS_PAGE_NO";

export interface VersionsState {
  versions: any;
  versionsPageNo: any;
  moreVersions: boolean;
  filter: any;
}

const initialState = {
  versions: [],
  versionsPageNo: 1,
  moreVersions: true,
  filter: undefined,
};

export const VersionReducer = (state: VersionsState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_VERSIONS: {
      let versions = [];
      const moreVersions = !(action.payload.length < constants.PAGINATION_SIZE);

      if (state.versionsPageNo === 1 || action.page === 1) {
        versions = [...action.payload];
      } else {
        versions = [...state.versions, ...action.payload];
      }

      return { ...state, versions: versions, moreVersions: moreVersions };
    }

    case SET_VERSIONS_PAGE_NO: {
      const moreVersions = !(
        state.versions.length <
        (state.versionsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, versionsPageNo: action.payload, moreVersions: moreVersions };
    }

    case SET_FILTERING_CRITERIA_VERSIONS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_VERSIONS_PAGE_NO:
      return {
        ...state,
        versionsPageNo: state.versionsPageNo + 1,
      };
  }
  return state;
};

export const setVersionsPageNo = (payload: number): ACTION => ({
  type: SET_VERSIONS_PAGE_NO,
  payload,
});

export const fetchVersionList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `Version/VersionPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse) => {
        dispatch({
          type: FETCHED_VERSIONS,
          payload: (response.data as Record<string, unknown>[]).map(version =>
            deserialize(version)
          ),
          page: page,
        });
        dispatch({ type: constants.LOADING, payload: false });
      });
  };
};

export const createVersion = payload => {
  return http.post("Version/", serialize(payload, "Version"));
};

export const changeVersion = (id: string, payload) => {
  return http.put("Version/" + id, serialize(payload, "Version"));
};

export const deleteVersion = (id: string) => {
  return http.delete("Version/" + id);
};

export const getVersionById = (id: string) => {
  return http.get("Version/" + id);
};

export const incrementVersionsPageNo = () => ({
  type: INCREMENT_VERSIONS_PAGE_NO,
});
