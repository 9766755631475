// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3XJpSWVAiDVvPPEpl4jJt6{width:100%;height:171px;font-size:16px;box-sizing:border-box;border:2px solid var(--grey);resize:none;outline:0;transition:border-color linear 0.2s}._3XJpSWVAiDVvPPEpl4jJt6:focus,._3XJpSWVAiDVvPPEpl4jJt6:hover{border-color:var(--main);transition:border-color linear 0.2s}\n", "",{"version":3,"sources":["webpack://./src/components/OldInput/Textarea/Textarea.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAW,CACX,YAAa,CACb,cAAe,CACf,qBAAsB,CACtB,4BAA6B,CAC7B,WAAY,CACZ,SAAU,CACV,mCAAoC,CARtC,8DAYI,wBAAyB,CACzB,mCAAoC","sourcesContent":[".textarea {\n  width: 100%;\n  height: 171px;\n  font-size: 16px;\n  box-sizing: border-box;\n  border: 2px solid var(--grey);\n  resize: none;\n  outline: 0;\n  transition: border-color linear 0.2s;\n\n  &:focus,\n  &:hover {\n    border-color: var(--main);\n    transition: border-color linear 0.2s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "_3XJpSWVAiDVvPPEpl4jJt6"
};
export default ___CSS_LOADER_EXPORT___;
