import apiCaller from "../api/apiHandler";
import * as constants from "../constants";
import requestWraper from "../api/requestWraper";
import { deserialize } from "../api/jsonApiParser";

export const fetchFiles = (filter?) =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      let query = "";
      if (filter) {
        Object.keys(filter).forEach(key => {
          query += `${query ? "&" : "?"}Search=${filter[key]}`;
        });
      }
      query = "File/" + query;
      return await apiCaller("GET", query).then((response: any) => {
        return response.data.map(res => deserialize(res));
      });
      dispatch({ type: constants.LOADING, payload: false });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Something went wrong!";
    }
    return true;
  });
