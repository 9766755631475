import http from "../../../api/http";
import { TMailServerSettingsState } from "./types";
import { TAction } from "../../../redux/types";
import { LOADING, PAGINATION_SIZE } from "../../../constants";
import { buildFilterQueryString, deserialize, serialize } from "../../../api/jsonApiParser";
import { Dispatch } from "redux";

export const FETCHED_MAILSERVERSETTINGS = "FETCHED_MAILSERVERSETTINGS";
export const SET_FILTERING_CRITERIA_MAILSERVERSETTINGS =
  "SET_FILTERING_CRITERIA_MAILSERVERSETTINGS";
export const SET_MAILSERVERSETTINGS_PAGE_NO = "SET_MAILSERVERSETTINGS_PAGE_NO";
export const INCREMENT_MAILSERVERSETTINGS_PAGE_NO = "INCREMENT_MAILSERVERSETTINGS_PAGE_NO";

const initialState = {
  mailServerSettings: [],
  mailServerSettingsPageNo: 1,
  moreMailServerSettings: true,
  filter: undefined,
};

const MailServerSettingsReducer = (state: TMailServerSettingsState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_MAILSERVERSETTINGS: {
      let mailserversettings = [];
      const moreMailServerSettings = !(action.payload.length < PAGINATION_SIZE);

      if (state.mailServerSettingsPageNo === 1 || action.page === 1) {
        mailserversettings = [...action.payload];
      } else {
        mailserversettings = [...state.mailServerSettings, ...action.payload];
      }

      return {
        ...state,
        mailServerSettings: mailserversettings,
        moreMailServerSettings: moreMailServerSettings,
      };
    }

    case SET_MAILSERVERSETTINGS_PAGE_NO: {
      const moreMailServerSettings = !(
        state.mailServerSettings.length <
        (state.mailServerSettingsPageNo + 1) * PAGINATION_SIZE
      );
      return {
        ...state,
        mailServerSettingsPageNo: action.payload,
        moreMailServerSettings: moreMailServerSettings,
      };
    }

    case SET_FILTERING_CRITERIA_MAILSERVERSETTINGS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_MAILSERVERSETTINGS_PAGE_NO: {
      return {
        ...state,
        mailServerSettingsPageNo: state.mailServerSettingsPageNo + 1,
      };
    }
  }
  return state;
};

export const setMailServerSettingsPageNo = (payload: number): TAction => ({
  type: SET_MAILSERVERSETTINGS_PAGE_NO,
  payload,
});

export const incrementMailServerSettingsPageNo = () => ({
  type: INCREMENT_MAILSERVERSETTINGS_PAGE_NO,
});

export const fetchMailServerSettingsList = (
  page = 1,
  pageSize = 10,
  filter?: Record<string, unknown>
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    const query = `MailServerSetting/MailServerSettingPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: any) => {
        dispatch({
          type: FETCHED_MAILSERVERSETTINGS,
          payload: response.data.map(mailsetting => deserialize(mailsetting)),
          page: page,
        });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const createMailServerSetting = payload => {
  return http.post("MailServerSetting/", serialize(payload, "MailServerSetting"));
};

export const changePasswordMailServerSetting = (id: string, password: string) => {
  const info = { id: id, password: password };
  return http.post("MailServerSetting/ChangePassword", serialize(info, "ChangePassword"));
};

export const deleteMailServerSetting = (id: string) => {
  return http.delete("MailServerSetting/" + id);
};

export const changeMailServerSetting = (id: string, payload) => {
  return http.patch("MailServerSetting/" + id, payload);
};

export const getMailServerSettingById = (id: string) => {
  return http.get("MailServerSetting/" + id);
};

export { MailServerSettingsReducer };
