import * as yup from "yup";

const WebServiceSchema = yup.object().shape({
  id: yup.string(),
  webserviceName: yup.string().required(),
  webserviceBindingType: yup.object().required().nullable(),
  endpointUrl: yup.string().required(),
  closeTimeout: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  receiveTimeout: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  sendTimeout: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  openTimeout: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  maxBufferPoolSize: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  maxReceivedMessageSize: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  maxStringContentLength: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  maxArrayLength: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  maxBytesPerRead: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  maxDepth: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  authenticationType: yup.object().required().nullable(),
  username: yup.string().required(),
  password: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  passwordrepeat: yup.string().when("id", {
    is: val => !val,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "app.passwordsDontMatch")
      .required(),
    otherwise: yup.string(),
  }),
});

export { WebServiceSchema };
