import http from "../../../api/http";

export const importDatabase = (fileId: string, files: FormData) => {
  if (fileId) {
    return http.post("Database/Import?fileId=" + fileId);
  } else {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return http.post("Database/Import", files, config);
  }
};
