import * as constants from "../../../constants";
import { TAction, TDispatch } from "../../../redux/types";

import http from "@api/http";
import { TResponse } from "@api/types";
import { buildFilterQueryString, deserialize } from "@api/jsonApiParser";

export interface VersionLogState {
  versionLogs: any;
  versionLogsPageNo: any;
  moreVersionLogs: boolean;
  filter: any;
}

const initialState = {
  versionLogs: [],
  versionLogsPageNo: 1,
  moreVersionLogs: true,
  filter: undefined,
};

export const FETCHED_VERSIONLOGS = "FETCHED_VERSIONLOGS";
export const SET_FILTERING_CRITERIA_VERSIONLOGS = "SET_FILTERING_CRITERIA_VERSIONLOGS";
export const SET_VERSIONLOGS_PAGE_NO = "SET_VERSIONLOGS_PAGE_NO";
export const INCREMENT_VERSIONLOG_PAGE_NO = "INCREMENT_VERSIONLOG_PAGE_NO";

export const VersionLogReducer = (state: VersionLogState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_VERSIONLOGS: {
      let versionLogs = [];
      const moreVersionLogs = !(action.payload.length < constants.PAGINATION_SIZE);

      if (state.versionLogsPageNo === 1 || action.page === 1) {
        versionLogs = [...action.payload];
      } else {
        versionLogs = [...state.versionLogs, ...action.payload];
      }

      return { ...state, versionLogs: versionLogs, moreVersionLogs: moreVersionLogs };
    }

    case SET_VERSIONLOGS_PAGE_NO: {
      const moreVersionLogs = !(
        state.versionLogs.length <
        (state.versionLogsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, versionLogsPageNo: action.payload, moreVersionLogs: moreVersionLogs };
    }

    case SET_FILTERING_CRITERIA_VERSIONLOGS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_VERSIONLOG_PAGE_NO: {
      return {
        ...state,
        versionLogsPageNo: state.versionLogsPageNo + 1,
      };
    }
  }
  return state;
};

export const setVersionLogsPageNo = (payload: number): TAction => ({
  type: SET_VERSIONLOGS_PAGE_NO,
  payload,
});

export const fetchVersionLogList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `VersionLogEntry/VersionLogEntryPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse) => {
        dispatch({
          type: FETCHED_VERSIONLOGS,
          payload: (response.data as Record<string, unknown>[]).map(versionLogList =>
            deserialize(versionLogList)
          ),
          page: page,
        });
        dispatch({ type: constants.LOADING, payload: false });
      });
  };
};

export const incrementVersionLogPageNo = () => ({
  type: INCREMENT_VERSIONLOG_PAGE_NO,
});
